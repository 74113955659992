/*

*/
/*//*/
* {
  box-sizing: border-box;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img,
a {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #222;
  font-size: 1em;
  font-family: "Noto Sans KR", sans-serif;
  word-break: keep-all;
  word-wrap: break-word;
  line-height: 1.6;
}
a {
  -webkit-tap-highlight-color: transparent;
}
html {
  font-size: 16px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overscroll-behavior: contain;
  overflow-y: scroll;
}

body.active {
  overflow-y: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #222;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: block;
  border: 0;
}
button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
select,
textarea {
  width: 100%;
  background: none;
  border: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input:focus-visible {
  outline-width: 0;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #bbb;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 공통  css */
.textarea_contents {
  white-space: pre-line;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
